import React, { useEffect } from "react";
import App from "next/app";
import getConfig from "next/config";
import Router from "next/router";
import NProgress from "nprogress";
import DisclaimerPeru from "../shared-components/Components/Disclaimers/DisclaimerPeru/web";
import Cookies from "universal-cookie";
import { BugReport } from "../src/Components/BugReport/web";
import "../shared-components/Styles/GlobalStyles.less";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const { sentryDNS, NODE_ENV, APP_NAME, APP_VERSION } = getConfig().publicRuntimeConfig;

const FeedbackTransport = async event => {
	let isBot =
		navigator && /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
	if (isBot) {
		HandleGoogleBotErrors(event, navigator?.userAgent);
		return null;
	} else {
		return null;
	}
};

const initializeSentry = async () => {
	if (typeof window !== "undefined" && sentryDNS) {
		const Sentry = await import("@sentry/browser");
		Sentry.init({
			dsn: sentryDNS,
			debug: NODE_ENV === "development",
			environment: NODE_ENV,
			release: APP_NAME + " " + APP_VERSION,
			ignoreErrors: ["ResizeObserver loop limit exceeded"],
			beforeSend: (e, h) => {
				if (typeof window != "undefined" && !window.navigator.onLine) return null;
				const error = h.originalException;
				if (
					error &&
					error.message &&
					error.message.match(/(typeError|network error):\s*(cancelado|cancelled)/i)
				) {
					return null;
				}
				return e;
			},
			transport: FeedbackTransport,
		});
	}
};

const MyApp = ({ Component, pageProps }) => {
	useEffect(() => {
		initializeSentry();
		Router.events.on("routeChangeComplete", () => window.scroll(0, 0));
		if (NODE_ENV === "production") {
			const coockies = new Cookies();
			if (!coockies.get("stick_ic2")) {
				coockies.set("stick_ic2", 1);
			}
		}
	}, []);

	return (
		<React.Fragment>
			<DisclaimerPeru data={pageProps} />
			<Component {...pageProps} />
			<BugReport />
		</React.Fragment>
	);
};

export default MyApp;

